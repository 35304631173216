import React, {useState, useEffect} from 'react';
import './App.css';
import BarPlot from './components/BarPlot';



function App() {
  return (
    <div className="App">
        <BarPlot/>
    </div>
  );
}

export default App;
