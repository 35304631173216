import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import levenshtein from 'fast-levenshtein';
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
var dawaAutocomplete2 = require('dawa-autocomplete2');


const AddressInput = ({ onSelectOwner, style, ownerNames }) => {
    const [address, setAddress] = useState('');
    const [error, setError] = useState(''); // State to store the error message
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            dawaAutocomplete2.dawaAutocomplete(inputRef.current, {
                select: (selected) => {
                    setAddress(selected.tekst);
                    // You can also call API to fetch owner based on selected address here
                }
            });
        }
    }, [inputRef]);

    const findClosestMatchingOwner = (apiOwnerName) => {
        let closestMatch = '';
        let smallestDistance = Infinity;
        const apiOwnerPrefix = apiOwnerName.substring(0, 4).toLowerCase();


        ownerNames.forEach(ownerName => {
            const ownerPrefix = ownerName.substring(0, 4).toLowerCase();
            const distance = levenshtein.get(apiOwnerPrefix, ownerPrefix);
            //console.log("LEVENSHTEIN DISTANCE:", distance,"From:",apiOwnerPrefix,"To:", ownerPrefix)
            if (distance < smallestDistance) {
                smallestDistance = distance;
                closestMatch = ownerName;
            }
        });
        //console.log("CLOSEST MATCH FROM LEVENSHTEIN",closestMatch)
        return closestMatch;
    };

    const fetchOwner = () => {
        const url = `https://api.elnet.greenpowerdenmark.dk/api/supplierlookup/${encodeURIComponent(address)}`;
        //console.log(url)
        axios.get(url)
            .then(response => {

                const result = response.data.name;
                console.log("RESULT", result)
                const ownerName = findClosestMatchingOwner(result)
                //console.log("FETCHED OWNER NAME:",ownerName,ownerNames)
                onSelectOwner(ownerName);
            })
            .catch(error => {
                console.error('Error fetching owner:', error);
                setError('Det var ikke muligt at finde ElSelskab ud fra denne Adresse. Prøv igen'); // Set error message

            });
    };
    /*
   const AdressInputWrapperStyle = {
       position: 'absolute',
       bottom: '30px',
       right: '0px'
   };
   
   // Style for the selector itself
  
   const AdressInputStyle = {
       fontSize: '0.7em', // Increase font size
       borderRadius:'5px',
       padding: '5px 10px' // Increase padding for larger appearance
   };

   const AdressInputButtonstyle = {
       position:'absolute',
       fontSize: '0.7em',
       borderRadius: '5px',
       bottom:'0px',
       right:'0px',
       padding: '5px 10px'
   }

   const errorMessageStyle = {
       color: '#e6c532'

   }

   const isMobile = window.innerWidth <= 480;

   const containerStyle = {
       position: 'relative',
       flexGrow: 1,
       display: 'flex',
       width: '100%', // Ensure the container takes full width
       fontSize: '16px',
       '@media screen and (maxWidth: 600px)': {
           width: '100%',
           marginBottom: '10px'
       }
   };

   const inputStyle = {
       fontSize: '1.7em',
       display: 'flex',
       padding: '20px',
       //paddingLeft: '0px',
       paddingRight: '50px', // Make room for the button
       margin: '5px',
       borderRadius: '5px',
       border: '2px solid #4caf50',
       width: '100%', // Full width of the container
       boxSizing: 'border-box', // Include padding and border in the width
       '@media screen and (maxWidth: 600px)': {
           width: '100%',
           marginBottom: '10px',
           fontSize: '0.7em',
       }
   };

   const buttonStyle = {
       position: 'relative',
       display: 'flex',
       right: '10px',
       top: '50%',
       transform: 'translateY(-50%)', // Center vertically
       padding: '10px 20px',
       margin: '0',
       borderRadius: '0 5px 5px 0', // Rounded corners on the right side
       backgroundColor: '#4CAF50',
       color: 'white',
       border: 'none',
       cursor: 'pointer',
       height: 'calc(100% - 10px)', // Adjust height relative to the container
       boxSizing: 'border-box', // Include padding and border in the height
       alignItems: 'center',
   };
   */

    return (
        <div className="autocomplete-container">
            <div className='searchWrapper'>
                <input
                    ref={inputRef}
                    type="text"
                    id="dawa-autocomplete-input"
                    className='input searchInput'
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Indtast adresse"

                />
                <button
                    className='searchButton'
                    onClick={fetchOwner}
                >
                    <i className="fa-solid fa-magnifying-glass"></i>
                </button>
            </div>
        </div>
    );
};


export default AddressInput;
