import React, { useState, useEffect, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, LabelList, Cell, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import Selector from './Selector';
import AddressInput from './AddressInput';
import '../App.css';

const BarPlot = () => {
    const [data, setData] = useState([]);
    const [priceArea, setPriceArea] = useState('DK1'); // State to track selected price area
    const [dhpData, setDhpData] = useState([]);
    const [selectedOwner, setSelectedOwner] = useState('');



    useEffect(() => {
        axios.get('https://aicron.s3.jfmedier.dk/time/elpris.json')
            .then(response => {
                //Process Elspotpris Data
                const processedData = response.data.ElspotPrice.map(item => ({
                    ...item,
                    SpotPriceDKK: item.SpotPriceDKK / 1000, // Divide SpotPriceDKK by 1000
                    Hour: new Date(item.HourDK).getHours() // Extract hour from HourDK
                })).sort((a, b) => a.Hour - b.Hour); // Sort by hour


                setData(processedData);
                //Store DHP Data
                setDhpData(response.data.DataHubPriceList);
                console.log("DHP DATA:", response.data.DataHubPriceList)

                /*
                if (response.data.DataHubPriceList.length > 0) {
                    setSelectedOwner(response.data.DataHubPriceList[0].ChargeOwner);
                }*/
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
            });
    }, []);

    // Filter data based on selected price area
    //const filteredData = data.filter(item => item.PriceArea === priceArea);
    //console.log("Filtered Data:",filteredData)

    const ownerNames = useMemo(() => {
        const names = new Set(dhpData.map(d => d.ChargeOwner));
        return Array.from(names);
    }, [dhpData]);

    // Toggle function
    //const togglePriceArea = () => {setPriceArea(current => (current === 'DK1' ? 'DK2' : 'DK1'));};

    // Custom formatter function for XAxis ticks

    const renderCustomizedLabel = (props) => {
        const { x, y, width, value } = props;
        if (isNaN(value)) return null; // Skip rendering if value is NaN

        // Determine the font size based on screen width
        const mobileBreakpoint = 480; // Define your mobile breakpoint
        const fontSize = window.innerWidth < mobileBreakpoint ? '7px' : '12px';
        const barheight = window.innerWidth < mobileBreakpoint ? 0 : 4;

        return (
            <text x={x + width / 2} y={y - barheight} fill="#000" textAnchor="middle" dominantBaseline="top" style={{ fontSize: fontSize }}>
                {value.toFixed(2)}
            </text>
        );
    };

    // Function to determine bar color based on value
    const barColor = (value) => {
        if (value < lowerQuartile) return '#5dff9c'; // Green for below lower quartile
        if (value > upperQuartile) return '#d54052'; // Red for above upper quartile
        return '#efd561'; // Yellow otherwise
    };

    // Custom tooltip component
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            //console.log("CUSTOM TOOLTIP PAYLOAD",payload)
            return (
                <div className="custom-tooltip">
                    <h3 className="hour">{`Kl.: ${payload[0].payload.Hour}:00`}</h3>
                    <p className="elspot">{`Elpris: ${payload[0].payload.SpotPriceDKK.toFixed(2)}kr. per kWh`}</p>
                    <p className="elafgift">{`Elafgift: ${payload[0].payload.additionalTaxCharge.toFixed(2)} kr. per kWh`}</p>
                    <p className="eltarif">{`Nettarif: ${payload[0].payload.additionalCharge.toFixed(2)} kr. per kWh`}</p>
                    {/* Add more content here if needed */}
                </div>
            );
        }

        return null;
    };

    // Button style based on selection '#4CAF50' : '#4a4242'
    const buttonStyle = (area) => ({
        backgroundColor: priceArea === area ? '#173f3f' : '#dcdcdc',
        color: priceArea === area ? 'white' : 'black', // White for selected, black for non-selected
    });

    // Find the selected owner's data
    const selectedOwnerData = dhpData.find(d => d.ChargeOwner === selectedOwner) || {};

    // Combine ESP data with additional charges from DHP data
    const combinedData = data.map((item) => {
        const hourKey = `Price${item.Hour}`;
        const additionalCharge = (selectedOwnerData[hourKey] ? parseFloat(selectedOwnerData[hourKey]) : 0) * 1.2; //nettarif C time inddelt efter time
        const additionalTaxCharge = 0.697 * 1.2 //taxes per 2023 h2
        const totalPrice = parseFloat(item.SpotPriceDKK) + additionalCharge + additionalTaxCharge;
        return { ...item, additionalCharge, additionalTaxCharge, TotalPrice: parseFloat(totalPrice.toFixed(2)) };
    });
    //console.log("Combined Data: ", combinedData);


    // Filtered data based on price area
    const filteredCombinedData = combinedData.filter(item => item.PriceArea === priceArea);
    //console.log("Filtered Combined Data: ",filteredCombinedData)

    // Function to remove duplicates
    const getUniqueOwners = (data) => {
        const unique = new Map();
        data.forEach(item => {
            if (!unique.has(item.ChargeOwner)) {
                unique.set(item.ChargeOwner, item);
            }
        });
        return Array.from(unique.keys());
    };

    // Use this function to get unique owners
    const uniqueOwners = getUniqueOwners(dhpData);
    const sortedOwners = [...uniqueOwners].sort((a, b) => a.localeCompare(b));

    // Function to calculate quartiles
    const calculateQuartiles = (data) => {
        const values = data.map(d => d.TotalPrice).sort((a, b) => a - b);
        const lowerQuartile = values[Math.floor(values.length / 4)];
        const upperQuartile = values[Math.ceil(values.length * (3 / 4))];

        return { lowerQuartile, upperQuartile };
    };

    const { lowerQuartile, upperQuartile } = calculateQuartiles(filteredCombinedData);

    //console.log(lowerQuartile, upperQuartile)

    const getCurrentHourInDenmark = () => {
        const now = new Date();
        const denmarkTime = new Intl.DateTimeFormat('en-US', {
            hour: '2-digit',
            hour12: false,
            timeZone: 'Europe/Copenhagen'
        }).format(now);

        return parseInt(denmarkTime, 10); // Convert to 24-hour format as an integer
    };

    const currentHour = getCurrentHourInDenmark();

    const CustomTick = ({ x, y, payload }) => {
        const currentHour = getCurrentHourInDenmark();
        const hour = new Date(payload.value).getHours();
        const isCurrentHour = hour === currentHour;

        const textStyle = {
            fontSize: isCurrentHour ? '14px' : '13px',
            fill: isCurrentHour ? '#070707' : '#666', // Highlight color for current hour
        };

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="middle" style={textStyle}>
                    {`${hour.toString().padStart(2, '0')}`}
                </text>
            </g>
        );
    };

    const isMobile = window.innerWidth <= 480;
    const isTablet = window.innerWidth <= 1000 && window.innerWidth >= 480;

    const mathceil = isMobile ? 1.2 : 1.6;

    const xaxisInterval = isMobile ? 3 : 0 || isTablet ? 1 : 0;

    const barSize = isMobile ? 8 : 20 && isTablet ? 12 : 20;

    const barChartStyle = {
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '200px',
        marginLeft: '-1.6rem'
    };


    return (
        <div className='wrapperBox'>
            <div className='header'>
                <h1 style={{ marginLeft: '10px' }}>
                    <i className="fa-regular fa-lightbulb fa-fade" style={{ color: '#173f3f' }}></i> Lokale elpriser
                </h1>
            </div>

            <div className='searchBox'>
                <h2>Find din lokale elpris</h2>
                {/*<p>Indtast adresse eller vælg selskab.</p>*/}

                <div className='formGroup'>
                    <div className='addressWrapper'>
                        <AddressInput
                            onSelectOwner={setSelectedOwner}
                            ownerNames={ownerNames}
                        />
                    </div>
{/* 
                    <div className='ownerWrapper'>
                        <Selector
                            owners={sortedOwners}
                            onSelectOwner={setSelectedOwner}
                            selectedOwner={selectedOwner}
                        />
                    </div> */}
                </div>
            </div>
            <div className='regionSelectionWrapper'>
                <button
                    style={buttonStyle('DK1')}
                    className='input regionButton'
                    onClick={() => setPriceArea('DK1')}
                >
                    Vestdanmark
                </button>
                <button
                    style={buttonStyle('DK2')}
                    className='input regionButton'
                    onClick={() => setPriceArea('DK2')}
                >
                    Østdanmark
                </button>
            </div>
            <div style={barChartStyle}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        //style={responsiveStyle}
                        //width={900} height={400}
                        data={filteredCombinedData}

                    >
                        <CartesianGrid vertical={false} />
                        <XAxis dataKey="HourDK"
                            interval={xaxisInterval}
                            tick={CustomTick} />
                        <YAxis
                            tick={{ fontSize: '12px' }}
                            domain={[0, (dataMax) => Math.ceil(dataMax * mathceil)]} />
                        <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
                        <Bar
                            dataKey="TotalPrice" // Use the combined total price
                            fill="#8884d8"
                            radius={[60, 60, 10, 10]}
                            barSize={barSize}
                        >
                            {filteredCombinedData.map((entry, index) => {
                                const isCurrentHour = entry.Hour === currentHour;
                                const fillColor = barColor(entry.TotalPrice); // Highlight color for current hour
                                const fillOpac = isCurrentHour ? 0.65 : 1;
                                const stroke = isCurrentHour ? "#070707" : "none";
                                const strokew = isCurrentHour ? "2.25px" : "0px";
                                return <Cell key={`cell-${index}`} fill={fillColor} fillOpacity={fillOpac} stroke={stroke} strokeWidth={strokew} />;
                            })}
                            <LabelList dataKey="TotalPrice" content={renderCustomizedLabel} />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <div className='dataSourceText'>
            Datakilder: Energi Data Service, Green Power Denmark og DAWA
            </div>
        </div>
    );
};



export default BarPlot;
